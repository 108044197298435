<template>
  <div class="row justify-content-center">
    <div class="col-12 col-sm-8">
      <div class="pb-5">
        <VForm ref="form" v-slot="{ errors }" @submit="createUser">
          <h4 class="py-3 fw-bold text-primary">註冊新用戶</h4>
          <div class="row gx-lg-5 justify-content-cente">
            <div class="mb-3 col-12">
              <label for="account" class="form-label">帳號</label>
              <VField
                id="account"
                name="帳號"
                type="text"
                class="form-control"
                :class="{ 'is-invalid': errors['帳號'] }"
                placeholder="請輸入帳號"
                maxLength="20"
                rules="required"
                v-model="form.account"
              />
              <ErrorMessage name="帳號" class="invalid-feedback" />
            </div>
            <div class="mb-3 col-12">
              <label for="password" class="form-label">密碼</label>
              <VField
                ref="password"
                id="password"
                name="password"
                type="password"
                class="form-control"
                :class="{ 'is-invalid': errors['password'] }"
                placeholder="請輸入密碼"
                rules="required"
                maxLength="20"
                v-model="form.password"
              />
              <ErrorMessage name="password" class="invalid-feedback" />
            </div>
            <div class="mb-3 col-12">
              <label for="passwordAgain" class="form-label">再次輸入密碼</label>
              <VField
                id="passwordAgain"
                name="再次輸入密碼"
                type="password"
                class="form-control"
                :class="{ 'is-invalid': errors['再次輸入密碼'] }"
                placeholder="請再次輸入密碼"
                v-model="form.confirmPassword"
                maxLength="20"
                rules="required|confirmed:@password"
              />
              <ErrorMessage name="再次輸入密碼" class="invalid-feedback" />
            </div>
            <div class="mb-3 col-12">
              <div class="d-flex align-items-center">
                <label class="form-label m-0" for="article-active">管理員身份</label>
                <div class="switch-group ms-2">
                  <input type="checkbox" id="article-active" role="button" v-model="form.isAdmin" />
                  <div class="ico_switch"></div>
                </div>
              </div>
            </div>
            <div class="mb-5">
              <label for="description" class="form-label">描述</label>
              <textarea
                id="description"
                cols="30"
                rows="2"
                class="form-control"
                v-model="form.memo"
              ></textarea>
            </div>
            <div class="col-12">
              <button type="submit" class="btn btn-primary px-4 text-white col-12">建立用戶</button>
            </div>
          </div>
        </VForm>
      </div>
    </div>
  </div>
</template>

<script>
import { apiSignUp } from "@/api";
export default {
  name: "signup",
  data() {
    return {
      form: {
        account: null,
        password: null,
        confirmPassword: null,
        isAdmin: false,
        memo: "",
      },
    };
  },
  methods: {
    async createUser() {
      try {
        this.$vLoading(true);
        const { account, password, isAdmin, memo } = this.form;
        const requestMemo = memo ? { memo } : {};
        const data = {
          account,
          password,
          isAdmin,
          ...requestMemo,
        };
        const res = await apiSignUp(data);
        const { code, message } = res.data;
        if (code === 200) {
          this.$vHttpsNotice({ statusCode: code, title: "建立成功" });
        } else {
          this.$vHttpsNotice({ statusCode: code, title: message });
        }
      } catch (error) {
        this.$vErrorNotice();
      } finally {
        this.$vLoading(false);
        this.$refs.form.resetForm();
      }
    },
  },
};
</script>
